import React from 'react'
import './footer.css'
import {Container, Row, Col, ListGroup, ListGroupItem} from 'reactstrap'
import {Link} from 'react-router-dom'
import Logo from '../../assets/images/logo.png'

const Footer = () => {
  const quick__links =[
    {
      path: "/home",
      display: "Home"
    },
    {
      path: "/about",
      display: "About"
    },
    {
      path: "/tours",
      display: "Tours"
    },

  ]
  const quick__links2 =[
    {
      path: "/gallery",
      display: "Gallery"
    },
    {
      path: "/login",
      display: "Login"
    },
    {
      path: "/register",
      display: "Register"
    },

  ]

  const year = new Date().getFullYear()
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg='3'>
            <div className="logo">
              <img src={Logo} alt="" />
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur dolorem nostrum assumenda temporibus</p>
              <div className="social__links d-flex align-items-center gap-4">
                <span>
                  <Link to='' className='ri-youtube-line'></Link>
                </span>
                <span>
                  <Link to='' className='ri-github-fill'></Link>
                </span>
                <span>
                  <Link to='' className='ri-facebook-circle-line'></Link>
                </span>
                <span>
                  <Link to='' className='ri-instagram-line'></Link>
                </span>
              </div>
            </div>
          </Col>
          <Col lg='3'>
            <h5 className="footer__link-title">Discover</h5>
            <ListGroup className='footer__quick-links'>
              {
                quick__links.map((item, index) =>(
                  <ListGroupItem key={index} className='ps-0 border-0'>  
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))
              }
            </ListGroup>
          </Col>
          <Col lg='3'>
          <h5 className="footer__link-title">Quick Links</h5>
            <ListGroup className='footer__quick-links'>
              {
                quick__links2.map((item, index) =>(
                  <ListGroupItem key={index} className='ps-0 border-0'>  
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))
              }
            </ListGroup>
          </Col>
          <Col lg='3'>
          <h5 className="footer__link-title">Contacts</h5>
            <ListGroup className='footer__quick-links'>
              
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>  
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span><i className="ri-map-pin-line"></i> </span>
                  Address:
                </h6>
                <p className='mb-0'>Sylet, Bangledish</p>
              </ListGroupItem>

              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>  
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span><i className="ri-mail-line"></i> </span>
                  Email:
                </h6>
                <p className='mb-0'>dev.job@gmail.com</p>
              </ListGroupItem>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>  
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span><i className="ri-phone-line"></i> </span>
                  Phone:
                </h6>
                <p className='mb-0'>+254 725663378</p>
              </ListGroupItem>
            
            </ListGroup>
          </Col>
          <Col lg='12' className='text-center'>
          <p className='copyright'>Copyright {year}, designed and developed by Job Peter. All rights reserved.</p></Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer